import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button, ButtonPopover, Space } from '@hse-design/react';
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "buttonpopover"
    }}>{`ButtonPopover`}</h1>
    <pre><code parentName="pre" {...{}}>{`import { ButtonPopover } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks name="ButtonPopover" figma="https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=6879%3A17725" storybook="?path=/story/buttonpopover-buttonpopover--playground" vueStorybook="?path=/story/buttonpopover-buttonpopover--playground" mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`ButtonPopover — это комбинация компонентов Button и Popover. Popover прикрепляется к кнопке.`}</p>
    <p>{`ButtonPopover является контролируемым компонентом. Для его работы необходимо использовать внешнее состояние: передайте проп `}<inlineCode parentName="p">{`active`}</inlineCode>{` и обрабатывайте колбек `}<inlineCode parentName="p">{`onToggle`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [active, setActive] = useState(false)\n  return (\n    <div style={{ height: \'50px\' }}>\n      <ButtonPopover\n        active={active}\n        content={\n          <>\n            <div className=\"hse-Text_caption-medium-bold\">ButtonPopover</div>\n            <Space vertical size={Space.Size.small_2x} />\n            <div className=\"hse-Text_paragraph-small\">\n              A ButtonPopover is a light roll made from an egg batter similar\n              to that of Yorkshire pudding, typically baked in muffin tins or\n              dedicated popover pans, which have straight-walled sides rather\n              than angled\n            </div>\n            <Space vertical size={Space.Size.small} />\n            <Button onClick={() => setActive(false)} size={Button.Size.small}>\n              OK\n            </Button>\n          </>\n        }\n        baseProps={{\n          noPadding: false,\n        }}\n        position={\'auto\'}\n        onToggle={setActive}\n      >\n        <>{props.active ? \'Close\' : \'Open\'} ButtonPopover</>\n      </ButtonPopover>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Button,
      ButtonPopover,
      Space,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const [active, setActive] = useState(false);
        return <div style={{
          height: '50px'
        }}>
                <ButtonPopover active={active} content={<>
                        <div className="hse-Text_caption-medium-bold">
                          ButtonPopover
                        </div>
                        <Space vertical size={Space.Size.small_2x} mdxType="Space" />
                        <div className="hse-Text_paragraph-small">
                          A ButtonPopover is a light roll made from an egg batter similar to that
                          of Yorkshire pudding, typically baked in muffin tins or dedicated
                          popover pans, which have straight-walled sides rather than angled
                        </div>
                        <Space vertical size={Space.Size.small} mdxType="Space" />
                        <Button onClick={() => setActive(false)} size={Button.Size.small} mdxType="Button">
                          OK
                        </Button>
                      </>} baseProps={{
            noPadding: false
          }} position={'auto'} onToggle={setActive} mdxType="ButtonPopover">
                    <>{props.active ? 'Close' : 'Open'} ButtonPopover</>
                  </ButtonPopover>
              </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={ButtonPopover} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      